import { useState, useEffect } from 'react';

import Header from '../../Components/All/Header';
import { ContactInfo, ContactForm } from '../../Components/Contact';
import SEO from "../../Components/SEO";

import './contact.css';

const Contact = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 300);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`contact-page page-container ${loaded ? 'show' : ''}`}>
			<SEO
				title='Contact | MXI Solutions - Leading Web & Application Development Services'
				description="At MXI Solutions, we help businesses of all sizes harness technology that truly meets their needs and we are committed to providing exceptional customer service and support to all our customers.<br/><br/>Contact us now if you're interested to learn more about any of our services."
				name='MXI Solutions'
				type='website'
			/>
      <Header title="Contact"></Header>
      <ContactInfo></ContactInfo>
      <ContactForm></ContactForm>
    </div>
  );
};
export default Contact;
