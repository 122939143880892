import { useState, useEffect } from 'react';
import { 
    Banner1,
	ServiceContainer,
	ContactUsCTA,
	TeamMemberContainer,
	ProjectContainer,
	BlogsContainer,
	ClientContainer
} from '../../Components/Home'
import SEO from "../../Components/SEO";
import './home.css';

const Home = () => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
		const timer = setTimeout(() => setLoaded(true), 300);
		return () => clearTimeout(timer);
	}, []);
    
    return(
        <div className={`home-page page-container ${loaded ? 'show' : ''}`}>
			<SEO
				title='Home | MXI Solutions - Leading Web & Application Development Services'
				description='We help B2B SaaS companies simplify their workflows through automation and machine learning (AI)'
				name='MXI Solutions'
				type='website'
			/>
            <Banner1 />
            <ServiceContainer />
            <ProjectContainer />
            <ContactUsCTA />
            <TeamMemberContainer />
            <ClientContainer />
            <BlogsContainer />
        </div>
    )
};

export default Home;