import Header from "../../Components/All/Header";
import { BlogList, BlogMenu } from "../../Components/Blog";
import SEO from "../../Components/SEO";

const Blog = () => {

	return (
		<div>
			<SEO
				title='Blog | MXI Solutions - Leading Web & Application Development Services'
				description='The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should.'
				name='MXI Solutions'
				type='website'
			/>
			<Header title="Blogs"/>

			<div className="flex justify-center pb-20">
				<div className="flex flex-wrap max-w-[1400px] w-full px-9">
					<BlogList/>

					<BlogMenu/>
				</div>
			</div>
		</div>
	);
}
export default Blog;