import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

// Component Page
import Layout from './Pages/layout';
import Home from './Pages/Home';
import Service from './Pages/Service/';
import ServiceDetail from './Pages/ServiceDetail';
import Portfolio from './Pages/Portfolio';
import About from './Pages/About';
import Contact from './Pages/Contact';
// import FAQ from './Components/FAQ';
// import TOS from './Components/TOS';
// import Privacy from './Components/Privacy';
import PortfolioDetail from './Pages/PortfolioDetail';
import Blog from './Pages/Blog';
import BlogDetail from './Pages/BlogDetail';

// Hook
import ScrollToTop from './Utils/GlobalHelpers';
import './i18n';
import './fonts/Outfit.ttf';

// Helmet Provider
import { HelmetProvider } from 'react-helmet-async';

// Google Analytics
import { initGA, logPageView } from './analytics';

import './index_responsive.css';
import './index.css';

initGA();

const Root = () => {
  useEffect(() => {
    logPageView();
  }, []);

  return <Router>
      <HelmetProvider>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Service />} />
          <Route path="/services/:serviceId" element={<ServiceDetail />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio/:id" element={<PortfolioDetail />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogDetail />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/faq" element={<FAQ />} /> */}
          {/* <Route path="/term-of-service" element={<TOS />} /> */}
          {/* <Route path="/privacy-policy" element={<Privacy />} /> */}
        </Routes>
      </Layout>
      </HelmetProvider>
    </Router>
};

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
