import { Link } from 'react-router-dom';

import { PurpleDotHeader } from '../../All/PurpleDotHeader';

import '../Home.css';

// Import GSAP
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(useGSAP, ScrollTrigger);

const WhatsappLink="https://wa.me/601123313110?text=Hey%20MXI%2C%20i%20would%20like%20to%20know%20more...."

const ContactUsCTA = ({ 
    title="Need A Consultation?", 
    subtitle="WE ARE HERE TO ANSWER YOUR QUESTION 24/7",
    btnTitle="Let's Get Started"
}) => {
    
    return(
        <div className='contactUsCTA-container w-full relative'>
            <div className='bg-wrapper w-full max-w-full relative overflow-hidden'>
                <img className="w-full h-full object-cover object-[40%_33%]" src='/Assets/images/contactUs-bg.webp' alt="Teamwork"/>
                <svg className="purple-vector z-20 h-full absolute top-0 left-[10%]" width="589" height="461" viewBox="0 0 589 461" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M589 -2H232.5L0 615H345L589 -2Z" fill="#100584" fillOpacity="0.31"/>
                </svg>
                <svg className="grey-vector z-10 h-[150%] absolute top-[-88px] left-[10px]" width="473" height="459" viewBox="0 0 473 459" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 609.5V0.5H114L473 609.5H0Z" fill="#797979" fillOpacity="0.47"/>
                </svg>

            </div>
            <div className='content-wrapper absolute w-full max-w-[1400px] px-20 flex justify-between items-center flex-wrap inset-0 z-20 mx-auto'>
                <div className='content'>
                    <div className='sub-header text-white test-xl w-full'>
                        {subtitle}
                    </div>
                    <div className='header-white !py-0'>
                        {title}
                    </div>
                </div>

                <Link to='/contact' className='cta-btn mt-16 font-bold cursor-pointer'>
                    <div className=''>
                        {btnTitle}
                    </div>    
                </Link>
                
            </div>
        </div>
    )
};

// ------------------------------------------------------------------------------------//
// Start of ContactUsCTA2

const ContactUsCTA2 = () => {
    useGSAP(() => {
		const sections = gsap.utils.toArray(".descr-container .section-container");

		sections.forEach((section) => {
			gsap.fromTo(section, {
				opacity: 0,
				y: 120
			}, {
				opacity: 1,
				y: 0,
				scrollTrigger: {
					trigger: section,
                    once: true,
					start: "top 80%", // Start animation when the top of the section hits 80% of the viewport height
					end: "bottom 20%", // End when the bottom of the section hits 20% of the viewport height
					toggleActions: "play none none reverse", // Play on enter, reverse on leave
					// markers: true, // Optional: show markers for debugging
                    ease: "power4.out" // Easing function for smoothness
				}
			})
		})
	});

    return(
        <div className='ContactUsCTA2-container section'>
            <div className='max-w-[1400px] px-8 flex justify-between flex-wrap mx-auto pt-44 pb-16 tab1:pb-0'>
                <div className='content-container max-w-[40%] h-fit top-[120px]'>
					<PurpleDotHeader title="OUR STORY" />

                    <div className='header-black pt-8'>
                        A Humble Beginning: Our Path to Global Standards in Technology
                    </div>

                    <div className='btn-wrapper flex justify-left items-center mt-16 tab1:hidden'>
                        <Link className='cta-btn mr-4' to={WhatsappLink}>
                            Get To Know Us
                        </Link>
                        
                        <div className='contactDetails'>
                            <span className='text-purple-pri font-bold'>Call US: +6011-2331 3110</span>
                            <br/>
                            <span className="text-[#85858C]">For any inquiries</span>
                        </div>
                    </div>
                </div>

                <div className='descr-container w-[80%] max-w-[50%] relative top-[-3.5rem] pt-24 tab1:pt-3 smob1:px-4 stab1:px-8'>
                    <div className='section-container w-full mb-8'>
                        <div className='header text-2xl font-medium'>
                            The Mission
                        </div>
                        <div className='content py-4 text-lg font-light text-lgray1 leading-normal tab1:text-justify'>
                            At MXI Solutions, our mission is to empower businesses and enhance their efficiency 
                            and service delivery through technology. 
                            We achieve this through a deep understanding of our clients' needs, 
                            thoughtful designs that fit their unique workflows, 
                            and a passion for creating innovative products. 
                            Our aim extends beyond merely delivering solutions; 
                            we focus on helping businesses thrive and concentrate on what truly matters to them.
                        </div>
                    </div>
                    <div className='section-container w-full mb-8'>
                        <div className='header text-2xl font-medium'>
                            Our Growth and Evolution
                        </div>
                        <div className='content py-4 text-lg font-light text-lgray1 tab1:text-justify'>
                            What started as a team of one has now grown to five. 
                            We've successfully completed numerous projects that we're truly proud of. 
                            Our commitment to innovation and excellence has allowed us to expand beyond just software development 
                            into specialized areas like search engine optimization, graphic design, and marketing consulting.
                        </div>
                    </div>

                    <div className='section-container w-full mb-8'>
                        <div className='header text-2xl font-medium'>
                            Our Approach
                        </div>
                        <div className='content py-4 text-lg font-light text-lgray1 tab1:text-justify'>
                            At the heart of MXI Solutions lies a philosophy of partnership and collaboration. 
                            We believe in working alongside our clients as strategic partners, 
                            understanding their unique challenges and tailoring our solutions to meet their specific needs. 
                            This approach has not only endeared us to our clients but has also been instrumental in our growth.
                        </div>
                    </div>

                    <div className='section-container w-full mb-8'>
                        <div className='header text-2xl font-medium'>
                            Our People
                        </div>
                        <div className='content py-4 text-lg font-light text-lgray1 tab1:text-justify'>
                            Our team is our greatest asset. 
                            A blend of creative minds, tech wizards, and strategic thinkers, 
                            the MXI Solutions team embodies the spirit of innovation and excellence. 
                            Each member brings a wealth of experience and a unique perspective, 
                            contributing to our vibrant and dynamic work culture.

                        </div>
                    </div>

                    <div className='section-container w-full mb-8'>
                        <div className='header text-2xl font-medium'>
                            Looking Ahead
                        </div>
                        <div className='content py-4 text-lg font-light text-lgray1 tab1:text-justify'>
                            As we look to the future, MXI Solutions is poised for unprecedented growth. 
                            Our goal is not just to lead but to create products that change the lives. 
                            We are constantly exploring new frontiers, expanding our reach, and embracing new challenges. 
                            With a commitment to quality, a passion for innovation, and a relentless focus on client success, 
                            we are excited about the endless possibilities that the future holds.
                        </div>
                    </div>
                    {/* <div className='img-large max-w-[80%] absolute right-0'>
                        <img className="object-cover rounded-2xl" src="/Assets/images/image-placeholder.png" alt="Call MXI Solutions"/>
                    </div> */}
                </div>
            </div>
        </div>
    )
};

export {
    ContactUsCTA, 
    ContactUsCTA2
}