import { Link } from "react-router-dom";

const Header = ({ title, path, name }) => {
  return (
    <div className="
        page-header section w-full flex flex-col items-center tracking-widest 
        gap-8 des:gap-6 mob:gap-5 pt-36 tab1:pt-9 tab1:!gap-2 
        sdesk1:pt-20 pb-24 stab1:pb-6 mob1:!pb-5 sdesk1:pb-12">
      <div className="header flex items-center">
        <span className="gradient-text max-w-[850px] font-bold text-7xl mob1:!text-4xl stab1:!text-6xl tab1:!leading-none sdesk1:text-6xl">
          {title}
        </span>
      </div>

      <div className="breadcrumb text-black max:text-md mob:text-base des:text-md">
        <span>
          <Link to="/">Home</Link> &gt; 
          <Link to={`/${path !== undefined && path.split(' ').length > 1? path.split(' ')[0].toLowerCase() : "" }`}> {path}</Link> 
          <b className="underline">{` ${ name !== undefined ? name : title }`}</b></span>
      </div>
    </div>
  );
};
export default Header;
