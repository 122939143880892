import { Link } from 'react-router-dom';

import '../Home.css';

import { PurpleDotHeader } from '../../All/PurpleDotHeader';

const Banner1 = () => {
    return(
        <div className='banner1-container smob1:h-[89vh] z-0'>
            <div className='content-container'>
                <div className='cntnt-wrapper'>
                    <PurpleDotHeader title="ELIMINATE REPETITIVE TASKS"/>
                    <div className='main-title title-7xl pt-8'>
                        Clear Thinking Makes 
                        <span className='text-[#208BF2]'>Bright Future !</span>
                    </div>

                    <div className='paragraph-gray des:mt-6 tab1:mt-8 stab1:max-w-[60%] smob1:mt-6'>
                        We help B2B SaaS companies simplify their workflows through automation and machine learning (AI)
                    </div>

                    <Link className='btn-wrapper' to='/about'>
                        <div className='cta-btn des:mt-16 stab1:mt-16 tab1:mt-12'>
                            Discover More
                        </div>
                        <div className='discover-btn'></div>
                    </Link>
                </div>
            </div>

            <div className='bg-wrapper absolute right-0 top-[0%] max-w-[50%] tab1:top-0'>
                {/* <svg className="w-[122%] absolute top-[-10%] right-[-8%]" width="120%" height="120%" viewBox="0 0 831 1085" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 547.653L912 2V1083L2 547.653Z" stroke="#208BF2" strokeWidth="2"/>
                </svg> */}

                {/* <video className="banner-home-video" preload="auto" playsInline autoplay muted loop>
                    <source src="/Assets/videos/banner-home.webm" type="video/webm"/>
                    Your browser does not support the video tag.
                </video> */}
                <div className="relative">
                    <div 
                    className="img-wrap relative z-10"
                    dangerouslySetInnerHTML={{
                        __html: 
                        "<video class=\"w-full h-full object-cover top-0 banner-home-video\" id=\"bannerVideo\" preload=\"auto\" playsinline autoplay muted loop>" +
                            "<source src=\"/Assets/videos/banner-home.webm\" type=\"video/webm\" />" +
                        "</video>"
                    }}/>

                    <img className="max-w-[100%] absolute top-0 right-0" src='/Assets/images/Banner1Image.png' alt="MXI Banner Img"></img>
                </div>
            </div>
        </div>
    )
};

export default Banner1