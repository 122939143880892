import React, { useState } from 'react';

// Component
import { TopMenu, Menu, MenuList } from './Navbar';
import Footer from './Footer';

const Layout = ({ children }) => {
  const [dispNavbar, setDispNavbar] = useState(false);
  
  const handleMenu = (dispNavbar) => {
    setDispNavbar(!dispNavbar);
  };

  const hideMenu = () => {
    setDispNavbar(false)
  }

  return (
    <React.Fragment>
      <TopMenu></TopMenu>
      <Menu handleMenu={handleMenu} dispNavbar={dispNavbar}></Menu>
      <div
        className={dispNavbar ? 'menunav-container block fixed bg-transparent w-full z-50' : 'menunav-container hidden'}
      >
        <MenuList hideMenu={hideMenu}></MenuList>
      </div>
      <main>{children}</main>
      <Footer />
    </React.Fragment>
  );
};
export default Layout;
