import { useState } from 'react';
import { Link } from 'react-router-dom';

import { PurpleDotHeader } from '../../All/PurpleDotHeader';
import { ProjectCarousel } from '../../../Components/Utils/slider';

import '../Home.css';

import projectsData from '../data/projects';

const Project = (props) => {
    return(
        <Link to='/portfolio' className='w-full'>
            <div className='project-wrapper w-full h-[500px] relative flex items-center' onMouseOver={props.handleHover} onMouseLeave={props.quitHover}>
                <div className='image-wrapper w-full h-full'>
                    <img className={`w-full h-full object-cover fi ${props.data.img.classpath} ${ props.isHovering[props.data.id] ? "!brightness-50" : "" }`} src={props.data.img.default} alt="Project"/>
                </div>

                <div className={`project-title absolute w-[90%] h-[90%] ${ props.isHovering[props.data.id] ? "opacity-0" : "opacity-100" }`}>
                    <span className='absolute bottom-[5%] left-7 w-full text-white text-3xl smob1:text-xl font-semibold text-ellipsis truncate overflow-hidden'>
                        {props.data.name}
                    </span>
                </div>

                <div className={`hover-el absolute bottom-[5%] w-[90%] h-[90%] inset-x-0 text-center p-5 mx-auto rounded-md flex justify-center items-center flex-wrap content-center  ${ props.isHovering[props.data.id] ? "opacity-100 translate-y-0" : "opacity-0 translate-y-[30%]" }`}>
                    {/* <div className='icon-wrapper bg-white w-16 h-16 rounded-[50%] flex justify-center items-center relative bottom-5'>
                        <Icon icon="iconamoon:search" />
                    </div> */}

                    <div className='descr-wrapper w-[80%] text-left absolute top-8 left-7 flex flex-wrap'>
                        <span className='w-full text-white text-sm'>{props.data.category}</span><br/>
                        <span className='w-full text-white text-3xl font-semibold text-ellipsis truncate overflow-hidden' title={props.data.name}>{props.data.name}</span>
                        <p className='w-full text-white text-lg mt-6 flex flex-wrap whitespace-normal'>{props.data.description}</p>

                    </div>
                </div>
            </div>
        </Link>
        
    )
};

const ProjectContainer = () => {
    const [isHovering, setIsHovering] = useState({
    });

    // defining project component states
    if(Object.keys(isHovering).length < 1){
        let projectStates = {...isHovering};
    
        for(let i=0; i < projectsData.length; i++){
            projectStates[projectsData[i].id] = false;
        }

        setIsHovering({...projectStates});
    }

    // handle mouse-in
    const handleMouseOver = (id) => {
        let copyObject = {...isHovering};
        copyObject[id] = true;
        setIsHovering({...copyObject});
    };

    // handle mouse-out
    const handleMouseOut = () => {
        let copyObject = {...isHovering};
        Object.keys(copyObject).forEach(v => copyObject[v] = false);
        setIsHovering({...copyObject});
    };

    const CarouselSwiperOption = {
        slidesPerView: 4,
        spaceBetween: 10,
        breakpoints: {
            0: {
            slidesPerView: 1,
            spaceBetween: 10,
            },
            689: {
            slidesPerView: 2,
            spaceBetween: 10,
            },
            989: {
            slidesPerView: 3,
            spaceBetween: 10,
            },
            1150: {
            slidesPerView: 3,
            spaceBetween: 32,
            },
        },
    };

    // construct projects component
    const allprojects = [];
    for(let i=0; i < projectsData.length; i++){
        allprojects.push(
            <Project 
                key={projectsData[i].id} 
                data={projectsData[i]} 
                isHovering={isHovering} 
                handleHover={() => handleMouseOver(projectsData[i].id)} 
                quitHover={ ()=> handleMouseOut() } />
        )
        ;
    }

    return(
        <div className='Project-container desk1:px-20 ssdesk1:px-12'>
            <div className='max-w-[1400px] flex items-center flex-wrap mx-auto pb-36 relative'>
                <div className='header-wrapper w-full'>
					<PurpleDotHeader title="CASE STUDIES"/>
                    <div className='header-black pt-8'>
                        Illuminating Success Stories
                    </div>
                </div>

                <div className='content-wrapper w-full overflow-hidden pt-20 flex flex-wrap justify-between items-baseline'>
                    <ProjectCarousel  
                        data={allprojects} 
                        carouselSwiperOption={CarouselSwiperOption}
                    />
                </div>
            </div>
        </div>
    )
};

export {
    projectsData,
    Project,
    ProjectContainer
}
// end of project section