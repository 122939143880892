import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = ({title, description, name, type}) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta
                name="description"
                content={description}
            />
            <meta name="keywords" content="MXI Solutions, web development, application development, software development, in-house development, custom software solutions, multi-language support, framework support, JavaScript development, Angular development, React development, mobile app development, scalable web solutions, professional web services" />
            <meta name="author" content="MXI Solutions" />

            <meta property="og:type" content="website" />
            <meta
                property="og:title"
                content={title}
            />
            <meta
                property="og:description"
                content={description}
            />

            <meta name="twitter:creator" content="MXI Solutions" />
            <meta name="twitter:card" content="website" />
            <meta
                name="twitter:title"
                content={title}
            />
            <meta
                name="twitter:description"
                content={description}
            />
        </Helmet>
    )
}

export default SEO;