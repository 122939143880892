import { useState, useEffect } from 'react';

import Header from "../../Components/All/Header";
import { Hero , Testimonial} from "../../Components/About";
import { 
	StrongPointContainer, 
	TeamMemberContainer, 
	ContactUsCTA2, 
	ClientContainer 
} from "../../Components/Home";

// SEO
import SEO from "../../Components/SEO";

import './about.css';

const About = () => {
	const [loaded, setLoaded] = useState(false);
  
	useEffect(() => {
	  const timer = setTimeout(() => setLoaded(true), 300);
	  return () => clearTimeout(timer);
	}, []);

	return (
		<div className={`aboutus-page page-container ${loaded ? 'show' : ''}`}>
			<SEO
				title='About Us | MXI Solutions - Leading Web & Application Development Services'
				description='We help B2B SaaS companies simplify their day-to-day tasks through technology. We believe in providing solutions that not only work but are also aesthetically pleasing & easy to use without breaking their bank!'
				name='MXI Solutions'
				type='website'
			/>
			<Header title="About us"></Header>
			<Hero />
			<StrongPointContainer />
			<ContactUsCTA2 />
			<TeamMemberContainer />
			<Testimonial />
			<ClientContainer />
			
		</div>
	);		
}
export default About;