import { useState, useEffect } from 'react';

import Header from "../../Components/All/Header";
import Menu from "../../Components/Service/Menu";
import Contact from "../../Components/Service/Contact";
import Blog from "../../Components/Service/Blog";
import blogs from "./data/services";
import SEO from "../../Components/SEO";

import './Service.css';

const Service = () => {
	const [loaded, setLoaded] = useState(false);
  
	useEffect(() => {
	  const timer = setTimeout(() => setLoaded(true), 300);
	  return () => clearTimeout(timer);
	}, []);
	
	return (
		<div className={`service-page page-container ${loaded ? 'show' : ''}`}>
			<SEO
				title='Service | MXI Solutions - Leading Web & Application Development Services'
				description="Statistics reported by Mike Gualtieri from Forrester, a well-designed UI can boost a website's conversion rate by up to 200%. But that's not all, an enhanced UX design can take this a step further, potentially increasing conversion rates by up to a staggering 400%"
				name='MXI Solutions'
				type='website'
			/>

			<Header title="Service"></Header>
			<Menu></Menu>
			<Contact></Contact>
			<Blog items={blogs}></Blog>
		</div>
	);
}
export default Service;