import { Link } from "react-router-dom";

// Icons
import { BsCheckLg } from "react-icons/bs";
import { HiPhone } from "react-icons/hi"

import { PurpleDotHeader, DoublePurpleDotHeader } from "../All/PurpleDotHeader";

import SquareBoxCounter from "../All/CounterSquare";

import './about.css';

const Video = "/Assets/videos/aboutus/motion-video-aboutus-480p.webm"
const WhatsappLink="https://wa.me/601123313110?text=Hey%20MXI%2C%20i%20would%20like%20to%20know%20more...."

const Hero = () => {
	return (
		<div className="hero section flex justify-center pb-32 stab1:pb-24 smob1:pb-16">
			<div className="container flex flex-row gap-20 max-w-[1400px] p-6 sdesk1:flex-col mob1:!gap-9 tab1:gap-14">
				<div 
					className="image w-1/2 sdesk1:w-full smob1:!aspect-square sdesk1:aspect-video sdesk1:h-auto h-screen rounded-[80px] overflow-hidden relative"
					dangerouslySetInnerHTML={{
						__html: 
						"<div class=\"w-full\">" +
							"<video class=\"absolute w-full h-full object-cover top-0\" id=\"bannerVideo\" playsinline autoplay muted loop>" +
								"<source src=" + Video +" type=\"video/webm\" />" +
							"</video>" +
							"<img src=\"/Images/About/banner.webp\" alt=\"skele\" class=\"h-full w-full absolute object-cover object-top sdesk1:object-cover sdesk1:object-top z-10 desk1:top-60 sdesk1:top-20\"/>" +
						"</div>"
					}}/>

				<div className="detail w-1/2 sdesk1:w-full">
					<div className="header pb-12 stab1:pb-16 smob1:!pb-12">
						<PurpleDotHeader title="MAKING IMPACT THAT MATTERS"/>

						<div>
							<span className="hero-title header-black">
								Connecting Ideas Through Creativity
							</span>
						</div>

						<div>
							<p className="paragraph-gray">
								We help B2B SaaS companies simplify their day-to-day tasks through technology. 
								We believe in providing solutions that not only work but are also aesthetically pleasing & easy to use,<br/><br/>
								<b>Without breaking their bank!</b> &nbsp;
							</p>
							<ul className="paragraph-gray list-why list-disc mt-6 pl-12">
								<li>
									<b>Tailored solutions</b> for your workflow 
									{/* to boost productivity and saves time. */}
								</li>

								<li>
									<b>Flexible pricing</b>. 
									We work within your budget.
								</li>

								<li>
									<b>Progressive payment plan</b> only. 
									Reduced financial risk.
								</li>
							</ul>
						</div>
					</div>

					<div className="card w-full pl-8 pr-14 py-[44px] bg-gradient-to-r from-[#e8e8e8] to-[#f7f7f7] rounded-[30px] mob1:!px-8 mob1:!py-16 tab1:px-10 tab1:py-12">
						<div className="strongpoint flex flex-row smob1:flex-col gap-10 pb-12 mob1:!pb-6 mob1:!gap-4">
							<div className="bg-[#f1f1f1] rounded-full w-fit h-fit p-6 flex justify-center items-center text-purple text-4xl mob1:!text-xl mob1:!p-4 smob1:mb-2">
								<BsCheckLg/>
							</div>

							<div>
								<span className="title">
									We're offering 1-hour free consultation for the first 10-customers
								</span>
								
								<p className="paragraph-gray smob1:text-justify">
									We'll recommend the best solution for your needs, 
									with <b>no pressure or obligation to proceed</b>.
								</p>

								<div className="counter-wrap">
									<SquareBoxCounter current={3} limit={10} />
								</div>
							</div>
						</div>

						

						<div className="cta-wrap buttons flex flex-wrap gap-y-2 smob1:flex-row-reverse smob1:justify-end smob1:gap-y-4 smob1:mt-4" >
							<div className="cta-btn">
								<Link to="/contact">
									Learn More
								</Link>
							</div>

							<div className="callus-btn flex flex-row items-center">
								<Link className="m-clbtn-purple" to={WhatsappLink}>
									<HiPhone/>
								</Link>
								{/* <div ></div> */}
								<div className="descr flex flex-col">
									<Link to="tel:601124413110" className="font-medium text-lgray1 text-base mob1:!text-xs">
										Call us 24/7
									</Link>

									<span className="font-medium text-black text-xl mob1:!text-base">
										(+60) 11 2331 3110
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const Testimonial = () => {
	const pp1 = '/Assets/images/clients/qna.png';
	// const pp2 = '/Images/About/abouthero.png'
	// const pp3 = '/Images/About/abouthero.png'
	const person = 'Images/About/person.png';

	return (
		<div className="clientTalk-container section relative flex justify-center my-44 smob1:!mb-24">
			<div className="clientTalk-wrapper absolute h-full w-full top-0 bg-[rgba(222,222,222,0.2)]">
				<div>
					<img src={person} alt="Nik Aqim (CTO at MXI Solutions)" className="absolute h-[45%] bottom-0 left-[7%] sdesk1:hidden"/>
				</div>

				<div className="bubble1 animate-pulse block absolute left-[10%] top-[5%] h-[123px] tab1:h-[80px] w-[123px] tab1:w-[80px] rounded-full overflow-hidden bg-[#C7F2F9] tab1:bg-[#DD0013] shadow-[0px_1px_100px_1px_rgba(0,0,0,0.3)]"></div>

				<div className="block animate-pulse tab1:hidden absolute right-[11%] bottom-[10%] h-[60px] w-[60px] rounded-full overflow-hidden bg-[#ffb8be] shadow-[0px_1px_100px_1px_rgba(0,0,0,0.3)]"></div>

				<div className="bubble2 block tab1:hidden absolute right-[6%] top-[11%] h-[89px] w-[89px] rounded-full overflow-hidden shadow-[0px_1px_100px_1px_rgba(0,0,0,0.3)]">
					{/* <img src={pp2} alt="" className="object-fill animate-pulse"/> */}
				</div>

				<div className="bubble3 animate-pulse block tab1:hidden absolute top-[30%] right-[18%] sdesk1:right-[7%] h-[123px] sdesk1:h-[99px] w-[123px] sdesk1:w-[99px] rounded-full overflow-hidden shadow-[0px_1px_100px_1px_rgba(0,0,0,0.3)]">
					{/* <img src={pp2} alt="" className="object-fill animate-pulse"/> */}
				</div>
			</div>

			<div className="clientTalk-wrapper w-1/2 sdesk1:w-full sdesk1:px-9 tab1:px-4 relative text-center flex flex-col items-center pt-40 pb-28 smob1:pt-32 smob1:pb-16">
				<DoublePurpleDotHeader title="CLIENTS TALK"/>

				<div><span className="header-black">What our clients say</span></div>

				<div className="block h-48 w-48 rounded-full overflow-hidden mb-10 mt-20 shadow-[0px_1px_100px_1px_rgba(0,0,0,0.3)]">
					<img src={pp1} alt='Abdulla Ahmed (Technical Lead at Qatar News Agency)' className="object-contain brightness-125"/>
				</div>

				<div className="block pb-14">
					<span className="text-5xl text-black font-semibold pb-8 block mob1:!text-3xl tab1:text-4xl">"Elite partnership work"</span>

					<p className="paragraph-gray max-w-[100%] mx-auto">
						MXI Solutions understood all our needs while also providing solutions 
						that fit our unique requirements. They are quick to respond to our 
						requests. Their applications are not only easy to use but also 
						beautifully designed, making our experience while using them enjoyable. 
						We highly recommend working with them.
					</p>
				</div>

				<div>
					<div>
						<span className="text-3xl text-black font-medium tracking-wider pb-2 block mob1:!text-xl mob1:!pb-0 tab1:pb-1 tab1:text-2xl">
							Abdulla Ahmed
						</span>
					</div>

					<div>
						<span className="text-2xl text-gray font-medium tracking-wider mob1:!text-lg tab1:text-xl">
							Technical Lead at <span className="text-purple">(Qatar News Agency)</span>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export { Hero, Testimonial };