// src/analytics.js
import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize('G-SX6DWV6426');
};

export const logPageView = () => {
  ReactGA.send('pageview');
};
